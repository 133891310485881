<template>
    <div class="signout">
        <v-btn @click="signOut">ログアウト</v-btn>
    </div>
</template>

<script>
  import firebase from 'firebase'
  import InitMenus from './../consts/init_menus'

  export default {
    name: "Signout",
    data () {
      return {
        username: '',
        password: ''
      }
    },
    methods: {
      signOut: async function () {
        // await firebase.auth().signInWithEmailAndPassword(this.username, this.password)
        await firebase.auth().signOut()
          .then(() => 1)
          .catch(err => console.log(err))
        await this.$emit("update-menus", InitMenus)
        this.$router.push('sign-in')
      },
    }
  }
</script>
